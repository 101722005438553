import Company from "components/company";
import { useBreadCrumbs } from "components/use-hooks";
import { GET_COMPANY } from "graphql/query/company-gql";
import { Localize } from "components/service";
import { UploadLogo } from "components/layout";

const companyBasePath = Company.Const.basePath;

const CompanyOverviewSubPage = ({ company, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Companies" }).props
        .children,
      path: `${companyBasePath}`,
    },
    {
      label:
        (company && company?.title) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path:
        (company && `${companyBasePath}/${company?.id}`) ||
        `${companyBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Company.Forms.Edit company={company} history={history} />
      </div>
      <div className="col-right">
        {company?.id && (
          <UploadLogo
            width={400}
            label="FORMS.Input_Label_Logo"
            uploadButtonText="GLOBAL.Text_AddLogo"
            uploadImgSize="MODAL.Button_Text_LogoSize"
            model="Company"
            modelId={company?.id}
            modelField="logo_path"
            defaultMedia={company?.logo_path}
            query={GET_COMPANY}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyOverviewSubPage;
