import PostInfoField from "./post-info-field";
import PostActionField from "./post-action-field";
import PostTitleField from "./post-title-field";
import PostTopField from "./post-top-field";
import PostAttachmentField from "./post-attachment-field";
import PostAttachmentActionField from "./post-attachment-action-field";
import PostCommentField from "./post-comment-field";
import PostDescriptionField from "./post-description-field";

const PostFields = {
    Info: PostInfoField,
    Action: PostActionField,
    Title: PostTitleField,
    Top: PostTopField,
    Attachment: PostAttachmentField,
    AttachmentAction: PostAttachmentActionField,
    Comment: PostCommentField,
    Description: PostDescriptionField,
};

export default PostFields;
