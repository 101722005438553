import { Switch } from "antd";
import { userUseMutation } from "../hooks/index";

const UserRadioField = ({ user }) => {
    const { _setUserCreateUpdate } = userUseMutation.createUpdate(user?.id, false);

    return (
        <Switch
            checked={user?.diamond_status}
            onChange={(checked) => {
                _setUserCreateUpdate({
                    variables: {
                        input: {
                            id: user?.id,
                            diamond_status: checked,
                        },
                    },
                });
            }}
        />
    );
};

export default UserRadioField;
