import { useMutation } from "@apollo/client";
import { USER_EXPORT_EXEL } from "graphql/mutation/user-gql";
import {
    successNotification,
    errorNotification,
} from "components/request-result";
import { saveAs } from "file-saver";

const useMutationUserExportExel = () => {
    function getFileNameFromUrl(url) {
        const value = url.split("/");
        return value[value.length - 1];
    }

    const [_setUserExportExel, { loading }] = useMutation(USER_EXPORT_EXEL, {
        update(cache, { data }) {
            const {
                userExportExel: { label, message, file },
            } = data;

            successNotification({
                title: label,
                description: message,
            });

            saveAs(file, getFileNameFromUrl(file));
        },

        onError(error) {
            errorNotification(error);
        },
    });

    return {
        _setUserExportExel,
        loadingMutationUserExportExel: loading,
    };
};

export default useMutationUserExportExel;
