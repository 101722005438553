import React from "react";
import { Form, Button, Switch } from 'antd';
import { counterUseMutation } from '../../hooks';
import { Localize } from "components/service";

import './counter-form.scss';



const CounterForm = ({ summaryStatistic }) => {


    const [ form ] = Form.useForm();


    const summaryLabels = {
        knowledge_count: <Localize>SUMMARY.Label_Knys</Localize>,
        users_count: <Localize>SUMMARY.Label_Users</Localize>,
        users_visit_count: <Localize>SUMMARY.Label_Users_Visit</Localize>,
        posts_count: <Localize>SUMMARY.Label_Posts</Localize>,
        media_count: <Localize>SUMMARY.Label_Images</Localize>,
        attachments_count: <Localize>SUMMARY.Label_Documents</Localize>,
        comments_count: <Localize>SUMMARY.Label_Comments</Localize>,
        collections_count: <Localize>SUMMARY.Label_Collections</Localize>,
        hashtags_count: <Localize>SUMMARY.Label_Hashtags</Localize>,
        video_count: <Localize>SUMMARY.Label_Video</Localize>,
    };

    const {
        _setMutationChangeStatus,
    } = counterUseMutation.counterChange(summaryLabels);


    return (

        <Form
            key="edit-counter-form"
            layout="vertical"
            form={ form }
            className="model-form counter-form">

            <ul className="counters-list">
                { summaryStatistic
                    ?.filter(({ key }) => key !== 'teams_count')
                    ?.map(({ id, key, value, active, updated_at }) => {

                        const {
                            _setMutationUpdateStatus,
                            loadingMutationUpdateStatus,
                        } = counterUseMutation.counterUpdate(summaryLabels);

                        return (

                            <li key={ id } className="row-grid col-auto-200">

                                <Form.Item
                                    name={ key }
                                    initialValue={ active }
                                    className='form-item-switch'
                                    label={
                                        <div className="d-block text-center min-w-100px">
                                            { summaryLabels[ key ] }
                                            <strong className="counter-title">{ value }</strong>
                                        </div>

                                    }
                                >
                                    <Switch
                                        checked={ active }
                                        onChange={ (e) =>
                                            _setMutationChangeStatus({
                                                variables: {
                                                    key,
                                                    value: e
                                                }
                                            }) }
                                    />
                                </Form.Item>

                                <div className="update-box">
                                    <span className="title">Last Update: <br /> { updated_at }</span>

                                    <Button
                                        key={ key }
                                        loading={ loadingMutationUpdateStatus }
                                        onClick={ () =>
                                            _setMutationUpdateStatus({
                                                variables: { key }
                                            }) }
                                    >
                                        <Localize>FORMS.Button_Text_UpdateCount</Localize>
                                    </Button>
                                </div>
                            </li>)
                    }) }
            </ul>

        </Form>
    );
};

export default CounterForm;