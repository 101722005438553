import { gql } from "@apollo/client";

export const COMPANY_CREATE = gql`
  mutation CompanyCreate($input: CompanyFieldsInput!) {
    companyCreate(input: $input) {
      label
      message
      company {
        id
        title
        short_name
        import_num
        logo_path
        link_site
        created_at
        updated_at
      }
    }
  }
`;

export const COMPANY_UPDATE = gql`
  mutation CompanyUpdate($input: CompanyFieldsInput!) {
    companyUpdate(input: $input) {
      label
      message
      company {
        id
        title
        short_name
        import_num
        logo_path
        link_site
        created_at
        updated_at
      }
    }
  }
`;

export const COMPANY_DELETE = gql`
  mutation CompanyDelete($id: ID!) {
    companyDelete(id: $id) {
      label
      message
    }
  }
`;
