import React from "react";
import { Form, DatePicker, Button } from "antd";
import PostFormItems from "../fields";
import { GET_USERS_CURSOR } from "graphql/query/user-gql";
import { FormElements } from "components/layout";
import { Localize } from "components/service";
import {
  initialValFormatted,
  initialDateBetween,
} from "utils/helper-functions";

const { RangePicker } = DatePicker;

const PostFiltersForm = ({
  action,
  postsFilters,
  setCount,
  setFilters,
  setFilterList,
  filterList,
  filterInit,
  gqlBuilderWhere,
  modelFilters,
  formFieldsHide,
  form,
}) => {
  const onChangeSetFilter = (e, name, value) => {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    value.toString().length > 0
      ? setFilterList({
          ...filterList,
          [name]: {
            column: name,
            operator: "EQ",
            value: value,
          },
        })
      : setFilterList({ ...filterList, [name]: {} });
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      {formFieldsHide.includes("AUTHOR_ID") ? null : (
        <FormElements.RadioSelect
          skipQuery={!postsFilters}
          location="filter"
          form={form}
          query={GET_USERS_CURSOR}
          model="usersCursor"
          name="AUTHOR_ID"
          label="FORMS.Input_Label_User"
          modalTitle="FORMS.Title_ChooseUser"
          modelCursorLabel={["name"]}
          styleLabel={{ fontWeight: 700 }}
          initialValue={
            postsFilters?.AUTHOR_ID?.value
              ? [{ value: postsFilters?.AUTHOR_ID?.value }]
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />
      )}

      {formFieldsHide.includes("STATUS") ? null : (
        <PostFormItems.Status
          initialValue={initialValFormatted(postsFilters, "STATUS")}
          name="STATUS"
          form={form}
          onChangeSetFilter={onChangeSetFilter}
          placeholder={
            Localize({ children: "FORMS.Input_Placeholder_NotSelected" }).props
              .children
          }
        />
      )}

      {formFieldsHide.includes("CREATED_AT") ? null : (
        <Form.Item
          initialValue={initialDateBetween(postsFilters, "CREATED_AT")}
          name="CREATED_AT"
          label={<Localize>FORMS.Input_Label_CreatedAt</Localize>}
        >
          <RangePicker
            separator="&ndash;"
            placeholder={[
              Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props
                .children,
              Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props
                .children,
            ]}
            onChange={(_, dateSrings) =>
              dateSrings[0] !== ""
                ? setFilterList({
                    ...filterList,
                    CREATED_AT: {
                      column: "CREATED_AT",
                      operator: "BETWEEN",
                      value: dateSrings,
                    },
                  })
                : setFilterList({
                    ...filterList,
                    CREATED_AT: {},
                  })
            }
          />
        </Form.Item>
      )}

      <div className="form-btn-holder">
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(
              gqlBuilderWhere({ ...filterInit, ...postsFilters, ...filterList })
            );
            setCount({ ...postsFilters, ...filterList });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
        <Button
          className="ant-btn-secondary bg-light-gray btn-right"
          htmlType="submit"
          onClick={() => {
            setFilterList({});
            setCount({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem(modelFilters);
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default PostFiltersForm;
