import CompanyInfoField from "./company-info-field";
import CompanyActionField from './company-action-field';
import CompanyLogoField from './company-logo-field';



const CompanyFields = {
    Info : CompanyInfoField,
    Logo : CompanyLogoField,
    Action: CompanyActionField,
}

export default CompanyFields;