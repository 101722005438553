import React from "react";
import { Form, Button, DatePicker } from "antd";
import { GET_DEALERS_CURSOR } from "graphql/query/dealer-gql";
import { GET_USERS_CURSOR } from "graphql/query/user-gql";
import { Localize } from "components/service";

import { FormElements } from "components/layout";
import { initialDate } from "utils/helper-functions";

const SellerFiltersForm = ({
  action,
  sellersFilters,
  setCount,
  setFilters,
  setFilterList,
  filterList,
  gqlBuilderWhere,
  onChangeSetFilter,
  form,
}) => {
  return (
    <div className="ant-form ant-form-vertical filters-form">
      <Form.Item
        initialValue={initialDate(sellersFilters, "YEAR")}
        name="YEAR"
        label={<Localize>FORMS.Input_Label_Year</Localize>}
        onChange={onChangeSetFilter}
      >
        <DatePicker
          picker="year"
          placeholder={
            Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props
              .children
          }
          showTime
          format="YYYY"
          onChange={(_, dateSrings) =>
            dateSrings[0] !== ""
              ? setFilterList({
                  ...filterList,
                  YEAR: {
                    column: "YEAR",
                    operator: "EQ",
                    value: dateSrings,
                  },
                })
              : setFilterList({
                  ...filterList,
                  YEAR: {},
                })
          }
        />
      </Form.Item>

      <FormElements.RadioSelect
        skipQuery={!sellersFilters}
        location="filter"
        form={form}
        query={GET_USERS_CURSOR}
        model="usersCursor"
        name="USER_ID"
        label="FORMS.Input_Label_User"
        modalTitle="FORMS.Title_ChooseUser"
        modelCursorLabel={["name"]}
        styleLabel={{ fontWeight: 700 }}
        initialValue={
          sellersFilters?.USER_ID?.value
            ? [{ value: sellersFilters?.USER_ID?.value }]
            : false
        }
        onChangeSetFilter={onChangeSetFilter}
      />

      <FormElements.RadioSelect
        skipQuery={!sellersFilters}
        location="filter"
        form={form}
        query={GET_DEALERS_CURSOR}
        model="dealersCursor"
        name="DEALER_ID"
        label="FORMS.Input_Label_Dealer"
        modalTitle="FORMS.Title_ChooseDealer"
        modelCursorLabel={["company_name", "dealer_number"]}
        styleLabel={{ fontWeight: 700 }}
        initialValue={
          sellersFilters?.DEALER_ID?.value
            ? [{ value: sellersFilters?.DEALER_ID?.value }]
            : false
        }
        onChangeSetFilter={onChangeSetFilter}
      />

      <div className="form-btn-holder">
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(gqlBuilderWhere({ ...sellersFilters, ...filterList }));
            setCount({ ...sellersFilters, ...filterList });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
        <Button
          className="ant-btn-secondary bg-light-gray btn-right"
          htmlType="submit"
          onClick={() => {
            setFilterList({});
            setCount({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem("sellersFilters");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default SellerFiltersForm;
