import React from "react";
import { useQuery } from "@apollo/client";
import { GET_COMPANY } from "graphql/query/company-gql";
import { useActiveMenuItem } from "components/use-hooks";
import CompanyRoute from "components/routes/company/company-route";
import { PageWrapDefault } from "components/layout";
import { Localize } from "components/service";

const CompanyPage = ({ match, history }) => {
  useActiveMenuItem(["companys"], ["companys"]);

  const path = history.location.pathname.split("/").pop();
  const companyID = match ? parseInt(match.params.id) : null;

  const { data: { company } = {}, loading } = useQuery(GET_COMPANY, {
    skip: !companyID,
    variables: {
      id: companyID,
    },
  });

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "overview",
    },
  ];

  return (
    <PageWrapDefault
      className="page-company"
      loading={loading}
      title={
        path === "create" ? (
          <Localize>PAGES.Title_CreateCompany</Localize>
        ) : !loading && company ? (
          company._name
        ) : (
          "Page 404"
        )
      }
      dataExist={path === "create" ? true : !loading && company}
      pageNavbar={!loading && company ? pageNavbar : false}
      staticPath={`/companys/${companyID}`}
    >
      <CompanyRoute company={company} history={history} loading={loading} />
    </PageWrapDefault>
  );
};

export default CompanyPage;
