import UserChangePasswordForm from "./user-change-password-form";
import UserEditForm from "./user-edit-form";
import UserAddForm from "./user-add-form";
import UserFiltersForm from "./user-filters-form";
import UserQuestionForm from "./user-question-form";
import UserStatisticsForm from "./user-statistics-form"
import UserFormItems from "./fields";


import './forms.scss';

const UserForms = {
    Fields: UserFormItems,
    ChangePassword: UserChangePasswordForm,
    Edit: UserEditForm,
    Add: UserAddForm,
    Question: UserQuestionForm,
    Statistic: UserStatisticsForm,
    Filter: UserFiltersForm,
};

export default UserForms;
