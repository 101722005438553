import { useState } from "react";
import { useQuery } from "@apollo/client";
import { DatePicker } from "antd";
import { GET_POSTS_TOP } from "graphql/query/post-gql";
import { GET_USERS_POSTS_TOP } from "graphql/query/user-gql";
import { GET_LOG_GRAPHIC } from "graphql/query/chart-gql";
import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import User from "components/user";
import Post from "components/post";
import { Localize } from "components/service";
import moment from "moment";

import Chart from "components/chart";
import Icons from "components/icons";

const { RangePicker } = DatePicker;

const Dashboard = () => {
    useActiveMenuItem(["dashboard"], ["dashboard"]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [numColumns, setNumColumns] = useState(null);

    const handleDateChange = (dates) => {
        if (dates) {
            setStartDate(dates[0].format("YYYY-MM-DD"));
            setEndDate(dates[1].format("YYYY-MM-DD"));
        } else {
            setStartDate(null);
            setEndDate(null);
            setNumColumns(null);
        }

        if (moment(dates[1]).diff(dates[0], "days") >= 14) {
            setNumColumns(2);
        }
        if (moment(dates[1]).diff(dates[0], "days") >= 30) {
            setNumColumns(1);
        }
    };

    const disabledDate = (current) => {
        return current && current > moment().endOf("day");
    };

    const { data: { postsTop } = {}, loading: loadingPosts } =
        useQuery(GET_POSTS_TOP);

    const { data: { users } = {}, loading: loadingUsers } =
        useQuery(GET_USERS_POSTS_TOP);

    let { data: { logGraphic } = {}, loading: loadingCharts } = useQuery(
        GET_LOG_GRAPHIC,
        {
            variables: {
                start_day: startDate,
                end_day: endDate,
            },
        }
    );

    logGraphic = logGraphic && Array.from(Object.entries(logGraphic));
    logGraphic && logGraphic.shift();

    const loading = loadingPosts && loadingUsers && loadingCharts;

    function getTranslationChartTitle(name) {
        switch (name) {
            case "attachments_count":
                return (
                    <Localize>DASHBOARD.Chart_Title_AttachmentsCount</Localize>
                );

            case "collections_count":
                return (
                    <Localize>DASHBOARD.Chart_Title_CollectionsCount</Localize>
                );

            case "comments_count":
                return <Localize>DASHBOARD.Chart_Title_CommentsCount</Localize>;

            case "hashtags_count":
                return <Localize>DASHBOARD.Chart_Title_HashtagsCount</Localize>;

            case "media_count":
                return <Localize>DASHBOARD.Chart_Title_MediaCount</Localize>;

            case "posts_count":
                return <Localize>DASHBOARD.Chart_Title_PostsCount</Localize>;

            case "users_count":
                return <Localize>DASHBOARD.Chart_Title_UsersCount</Localize>;

            case "users_visit_count":
                return (
                    <Localize>DASHBOARD.Chart_Title_UsersVisitCount</Localize>
                );

            default:
                return "";
        }
    }

    return (
        <PageWrapDefault
            loading={loading}
            dataExist={!loading}
            className="page-dashboard"
            title={<Localize>PAGES.Title_Dashboard</Localize>}
        >
            <div className="row-grid col-lg-2">
                <div className="col-left">
                    <h4>
                        <Localize>PAGES.SubTitle_TopPosts</Localize>
                    </h4>

                    {!postsTop?.data.length ? (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100 fz-200">
                            <Icons.NoContent type="posts" />
                            <p className="fz-20">
                                <Localize>PAGES.Text_NoTopPosts</Localize>
                            </p>
                        </div>
                    ) : (
                        <ul className="posts-top-list">
                            {postsTop?.data?.map((post) => (
                                <li
                                    key={post.id}
                                    style={{ marginBottom: "15px" }}
                                >
                                    <Post.Fields.Top
                                        post={post}
                                        loadingPosts={loadingPosts}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="col-right">
                    <h4>
                        <Localize>PAGES.SubTitle_TopAuthors</Localize>
                    </h4>

                    {!users?.data.length ? (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100 fz-200">
                            <Icons.NoContent type="userFollowing" />
                            <p className="fz-20">
                                <Localize>PAGES.Text_NoTopAuthors</Localize>
                            </p>
                        </div>
                    ) : (
                        <ul className="users-top-list">
                            {users?.data?.map((user) => (
                                <li
                                    key={user.id}
                                    style={{ marginBottom: "15px" }}
                                >
                                    <User.Fields.TopPosts
                                        user={user}
                                        loadingUsers={loadingUsers}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>

            <br />

            <div className="row-grid col-auto-400">
                <RangePicker
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                />
            </div>
            <ul
                className={`charts-list row-grid col-md-${
                    numColumns ?? "2"
                } col-lg-${numColumns ?? "3"} col-xl-${numColumns ?? "4"}`}
            >
                {logGraphic
                    ?.filter(
                        ([nameCounter, data]) =>
                            nameCounter !== "users_count" && data !== null
                    )
                    ?.map(([nameCounter, data]) => {
                        return (
                            <li key={nameCounter}>
                                <div className="header">
                                    <strong className="title">
                                        {getTranslationChartTitle(nameCounter)}
                                    </strong>
                                    <strong className="counter">
                                        <Localize>
                                            DASHBOARD.Chart_Count_Total
                                        </Localize>
                                        : {data[data.length - 1].total}
                                    </strong>
                                </div>

                                <Chart data={data} />
                            </li>
                        );
                    })}
            </ul>
        </PageWrapDefault>
    );
};

export default Dashboard;
