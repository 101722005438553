import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/company/sub-pages";

const CompanyRoute = ({ company, history }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`}>
        <SubPage.OverView company={company} history={history} />
      </Route>
    </Switch>
  );
};

export default CompanyRoute;
