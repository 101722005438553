import UserInfoField from "./user-info-field";
import UserActionField from './user-action-field';
import UserContactField from './user-contact-field';
import UserDealerField from './user-dealer-field';
import UserTopPostsField from './user-post-top-field';
import UserQuestionActionField from './user-question-action-field';
import UserQuestionAuthorField from './user-question-author-field';
import UserQuestionAnsweredField from './user-question-answered-field';
import UserQuestionQuestionField from './user-question-question-field';
import UserRadioField from './user-radio-field';



const UserFields = {
    Info : UserInfoField,
    Action: UserActionField,
    Contact: UserContactField,
    Dealer: UserDealerField,
    TopPosts: UserTopPostsField,
    Author: UserQuestionAuthorField,
    Answered: UserQuestionAnsweredField,
    ActionQuestion: UserQuestionActionField,
    Question: UserQuestionQuestionField,
    Radio: UserRadioField
}

export default UserFields;