import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "antd";
import { PlusOutlined /*DownloadOutlined*/ } from "@ant-design/icons";

import { GET_USERS } from "graphql/query/user-gql";

import User from "components/user";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables, Elements } from "components/layout";
import { Localize, UploadFile /*ModalStandard*/ } from "components/service";
import Icons from "components/icons";
import { gqlBuilderWhere } from "utils";
import "./user.scss";

const basePath = User.Const.basePath;
const tableHelper = User.Helpers.TableMain();

const UsersPage = () => {
    useActiveMenuItem(["users"], ["users"]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props
                .children,
            path: `${basePath}`,
        },
    ];

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${basePath}/page/:pageNum`);

    const objOrderBy = [{ column: "ID", order: "DESC" }];

    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState();
    const [resetSorting, setResetSorting] = useState(false);
    const [variables, setVariables] = useState({});

    const usersFilters = JSON.parse(localStorage.getItem("usersFilters"));
    const usersSorting = JSON.parse(localStorage.getItem("usersSorting"));

    const { _setImportReimport, loadingMutationImportReimport } =
        User.Hooks.importReimport({ variables });

    const { _setUserExportExel } = User.Hooks.exportExel();

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props
                .children,
            path: "users",
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_UserQuestions" })
                .props.children,
            path: "user-questions",
        },
    ];

    function transformOrderBy(arr1, column = []) {
        if (arr1[0].column === column[0] || arr1[0].column === column[1]) {
            let arr2 = [];

            for (let obj of arr1) {
                let user_counters = {
                    column: obj.column,
                    aggregate: "MAX",
                };
                let newObj = {
                    order: obj.order,
                    user_counters: user_counters,
                };
                arr2.push(newObj);
            }
            return arr2;
        } else {
            return arr1;
        }
    }

    return (
        <PageWrapDefault
            className="page-users"
            dataExist={true}
            title={<Localize>PAGES.Title_Users</Localize>}
            pageNavbar={pageNavbar}
            staticPath=""
        >
            <Tables.Main
                model="users"
                query={GET_USERS}
                varSet={{ perPage: 30 }}
                routeUrl={`${basePath}`}
                currentPage={match ? parseInt(match.params.pageNum) : 1}
                searchText={searchText}
                setVariables={setVariables}
                objFilter={filters || gqlBuilderWhere(usersFilters)}
                objOrderBy={usersSorting || objOrderBy}
                filterObjOrderBy={["PUBLIC_POSTS", "FOLLOWERS"]}
                transformOrderBy={transformOrderBy}
                sortingName="usersSorting"
                tableHelper={tableHelper}
                resetSorting={resetSorting}
            >
                <div className="table-action-bar">
                    <div className="col">
                        <User.Filters.Table
                            filters={filters}
                            setSearchText={setSearchText}
                            setFilters={setFilters}
                            usersFilters={usersFilters}
                            setResetSorting={setResetSorting}
                            objOrderBy={objOrderBy}
                        />
                    </div>

                    <div className="col">
                        {loadingMutationImportReimport && (
                            <Elements.Importing
                                loading={loadingMutationImportReimport}
                                modalTitle="The users file is importing…"
                            />
                        )}

                        <Button
                            type="primary"
                            onClick={() => _setUserExportExel()}
                        >
                            <Icons.Download />{" "}
                            <Localize>USER.Button_Text_UserExport</Localize>
                        </Button>

                        <UploadFile
                            setMutation={_setImportReimport}
                            loading={loadingMutationImportReimport}
                            accept=".xlx, .xlsx, .csv, .xml"
                            uploadBtn={
                                <Button type="primary">
                                    <span>
                                        <PlusOutlined />{" "}
                                        <span>
                                            <Localize>
                                                USER.Button_Text_Import
                                            </Localize>
                                        </span>
                                    </span>
                                </Button>
                            }
                        />

                        <Link
                            className="ant-btn ant-btn-primary"
                            to={`${basePath}/create`}
                        >
                            <span>
                                <PlusOutlined />
                                <Localize>USER.Button_Text_Create</Localize>
                            </span>
                        </Link>
                        {/*
            <ModalStandard
              width={380}
              extraClass={"modal-form"}
              modalButton={
                <Button type="primary">
                  <span>
                    <DownloadOutlined /> &nbsp;
                    <Localize>MODAL.Button_Text_UserStatistic</Localize>
                  </span>
                </Button>
              }
            >
              <strong className="modal-title">
                <Localize>FORMS.Title_UserStatistic</Localize>
              </strong>

              <User.Forms.Statistic />
            </ModalStandard> */}
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    );
};

export default UsersPage;
