import React from "react";


const PostDescriptionField = ({ post }) => {

    return (
        <>
            { post?.description ?
                <span style={ {
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    WebkitLineClamp: '5',
                    WebkitBoxOrient: 'vertical'
                } }>
                    { post?.description }
                </span>
                :
                <strong>&mdash;</strong>
            }
        </>
    )
}


export default PostDescriptionField;
