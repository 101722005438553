import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { COMPANY_UPDATE, COMPANY_CREATE } from "graphql/mutation/company-gql";
import CompanyConst from '../company-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationCompanyCreateUpdate = (id, redirect = true) => {

  const history = useHistory();

  const COMPANY_CREATE_UPDATE = id ? COMPANY_UPDATE : COMPANY_CREATE;
  const companyCreateUpdate = id ? 'companyUpdate' : 'companyCreate';

  const [ _setCompanyCreateUpdate, { loading } ] = useMutation(COMPANY_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ companyCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && history.push(CompanyConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setCompanyCreateUpdate,
    loadingMutationCompanyCreateUpdate: loading,
  }
};

export default useMutationCompanyCreateUpdate;