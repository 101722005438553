import React from "react";
import { Form, InputNumber, Button } from "antd";
import { GET_DEALERS_CURSOR } from "graphql/query/dealer-gql";
import UserFormItems from "../fields";
import { Localize } from "components/service";
import { FormElements } from "components/layout";
import { initialValFormatted } from "utils/helper-functions";

const UserFiltersForm = ({
    action,
    usersFilters,
    setCount,
    setFilters,
    modelFilters,
    setFilterList,
    filterList,
    gqlBuilderWhere,
    formFieldsHide,
    form,
}) => {
    const onChangeSetFilter = (e, name, value) => {
        if (e) {
            name = e.target.name;
            value = e.target.value;
        }

        if (name === "SELLER_NUMBER") {
            value.toString().length > 0
                ? setFilterList({
                      ...filterList,
                      [name]: {
                          column: name,
                          operator: "LIKE",
                          value: `${value}%`,
                      },
                  })
                : setFilterList({ ...filterList, [name]: {} });
        } else if (name === "LAST_VISIT_AT") {
            if (value === "yes") {
                setFilterList({
                    ...filterList,
                    [name]: {
                        column: name,
                        operator: "IS_NOT_NULL",
                    },
                });
            } else if (value === "no") {
                setFilterList({
                    ...filterList,
                    [name]: {
                        column: name,
                        operator: "IS_NULL",
                    },
                });
            } else {
                setFilterList({ ...filterList, [name]: { column: undefined } });
            }
        } else {
            value.toString().length > 0
                ? setFilterList({
                      ...filterList,
                      [name]: {
                          column: name,
                          operator: "EQ",
                          value: value,
                      },
                  })
                : setFilterList({ ...filterList, [name]: {} });
        }
    };

    return (
        <div className="ant-form ant-form-vertical filters-form">
            {formFieldsHide.includes("SELLER_NUMBER") ? null : (
                <Form.Item
                    initialValue={initialValFormatted(
                        usersFilters,
                        "SELLER_NUMBER"
                    )}
                    name="SELLER_NUMBER"
                    label={<Localize>FORMS.Input_Label_SellerNumber</Localize>}
                    onChange={onChangeSetFilter}
                >
                    <InputNumber
                        placeholder={
                            Localize({
                                children: "FORM_RULES.Required_SellerNumber",
                            }).props.children
                        }
                        name="SELLER_NUMBER"
                        min={3}
                        max={1000000}
                        controls={false}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            )}

            {formFieldsHide.includes("DEALER_ID") ? null : (
                <FormElements.RadioSelect
                    skipQuery={!usersFilters}
                    location="filter"
                    form={form}
                    query={GET_DEALERS_CURSOR}
                    model="dealersCursor"
                    name="DEALER_ID"
                    label="FORMS.Input_Label_Dealer"
                    modalTitle="FORMS.Title_ChooseDealer"
                    modelCursorLabel={["company_name", "dealer_number"]}
                    styleLabel={{ fontWeight: 700 }}
                    initialValue={
                        usersFilters?.DEALER_ID?.value
                            ? [{ value: usersFilters?.DEALER_ID?.value }]
                            : false
                    }
                    onChangeSetFilter={onChangeSetFilter}
                />
            )}

            {formFieldsHide.includes("STATUS") ? null : (
                <UserFormItems.StatusSelect
                    initialValue={initialValFormatted(usersFilters, "STATUS")}
                    name="STATUS"
                    form={form}
                    onChangeSetFilter={onChangeSetFilter}
                />
            )}

            {formFieldsHide.includes("DIAMOND_STATUS") ? null : (
                <UserFormItems.DiamondStatusSelect
                    initialValue={usersFilters?.DIAMOND_STATUS?.value}
                    name="DIAMOND_STATUS"
                    form={form}
                    onChangeSetFilter={onChangeSetFilter}
                />
            )}

            {formFieldsHide.includes("ROLE") ? null : (
                <UserFormItems.RoleSelect
                    initialValue={initialValFormatted(usersFilters, "ROLE")}
                    name="ROLE"
                    form={form}
                    onChangeSetFilter={onChangeSetFilter}
                />
            )}

            {formFieldsHide.includes("LAST_VISIT_AT") ? null : (
                <UserFormItems.VisitedSelect
                    initialValue={initialValFormatted(
                        usersFilters,
                        "LAST_VISIT_AT"
                    )}
                    name="LAST_VISIT_AT"
                    form={form}
                    onChangeSetFilter={onChangeSetFilter}
                />
            )}

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                        setFilters(
                            gqlBuilderWhere({ ...usersFilters, ...filterList })
                        );
                        setCount({ ...usersFilters, ...filterList });
                        action();
                    }}
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={() => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem(modelFilters);
                    }}
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    );
};

export default UserFiltersForm;
