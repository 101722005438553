import { gql } from "@apollo/client";
import { postFieldsFull } from "../fragment/post-frgm";

export const GET_POSTS = gql`
    query GetPosts(
        $text: String
        $first: Int
        $page: Int
        $type: TypeSearchPosts
        $id_type: ID
        $orderBy: [QueryPostsOrderByOrderByClause!]
        $where: QueryPostsWhereWhereConditions
    ) {
        posts(
            text: $text
            first: $first
            page: $page
            type: $type
            id_type: $id_type
            orderBy: $orderBy
            where: $where
        ) {
            paginatorInfo {
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                title
                description
                status
                slug
                media {
                    id
                    name
                    thumbnail
                }
                media_layout
                attachments {
                    id
                }
                author {
                    id
                    name
                    nickname
                    avatar
                    email
                    seller_number
                    user_counters {
                        public_posts
                        followers
                    }
                }
                comment_count
                like_count
                view_count
                attached_file_count
                media_count
                video_count
                type
                liked
                viewed
                inCollection
                created_at
                getCreatedAt
                updated_at
                order_date
                order_slug
            }
        }
    }
`;

export const GET_POSTS_TOP = gql`
    query GetPostsTop {
        postsTop(first: 5, page: 1) {
            paginatorInfo {
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                title
                description
                status
                slug
                media {
                    id
                    name
                    thumbnail
                }
                media_layout
                attachments {
                    id
                }
                author {
                    id
                    name
                    nickname
                    avatar
                    user_counters {
                        public_posts
                        followers
                    }
                }
                comment_count
                like_count
                view_count
                attached_file_count
                media_count
                video_count
                type
                liked
                viewed
                inCollection
                created_at
                updated_at
                order_date
                order_slug
            }
        }
    }
`;

export const GET_POST = gql`
    query GetPost($slug: String, $id: ID) {
        post(slug: $slug, id: $id) {
            ...postFieldsFull
        }
    }
    ${postFieldsFull}
`;
