import CompanyEditForm from "./company-edit-form";
import CompanyFormItems from "./fields";

import "./forms.scss";

const CompanyForms = {
  Edit: CompanyEditForm,
  Fields: CompanyFormItems,
};

export default CompanyForms;
