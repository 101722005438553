import useMutationUserCreateUpdate from './user-use-mutation-create-update';
import useMutationUserSendPass from "./user-use-mutation-send-password";
import useMutationUserImportReimport from "./user-use-mutation-import";
import useMutationUserDelete from "./user-use-mutation-delete";
import useMutationUserQuestionCreateUpdate from "./user-question-use-mutation-create-update";
import useMutationUserQuestionDelete from "./user-question-use-mutation-delete";
import useMutationUserStatistic from "./user-use-mutation-statistic"
import useMutationUserExportExel from "./user-use-mutation-export-exel";


export const userUseMutation = {
  createUpdate: useMutationUserCreateUpdate,
  sendPass: useMutationUserSendPass,
  importReimport: useMutationUserImportReimport,
  delete: useMutationUserDelete,
  questionCreateUpdate: useMutationUserQuestionCreateUpdate,
  statistic: useMutationUserStatistic,
  questionDelete: useMutationUserQuestionDelete,
  exportExel: useMutationUserExportExel,
}