import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { COMPANY_DELETE } from "graphql/mutation/company-gql";
import CompanyConst from '../company-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationCompanyDelete = (id) => {

  const history = useHistory();

  const [ _setMutationCompanyDelete, { loading } ] = useMutation(COMPANY_DELETE,
    {
      update(cache, { data }) {

        const {
          companyDelete: {
            label,
            message,
          }
        } = data;


        history.push(CompanyConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Company" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },

      onError(error) {
        errorNotification(error);
      },
    });

  return {
    _setMutationCompanyDelete,
    loadingMutationCompanyDelete: loading,
  }
};

export default useMutationCompanyDelete;