import { gql } from "@apollo/client";

export const GET_COMPANIES = gql`
  query GetCompanies(
    $text: String
    $first: Int
    $page: Int
    $orderBy: [QueryCompaniesOrderByOrderByClause!]
    $where: QueryCompaniesWhereWhereConditions
  ) {
    companies(
      text: $text
      first: $first
      page: $page
      orderBy: $orderBy
      where: $where
    ) {
      paginatorInfo {
        total
        count
        currentPage
        perPage
        lastPage
      }
      data {
        id
        title
        short_name
        import_num
        logo_path
        link_site
        created_at
        updated_at
      }
    }
  }
`;

export const GET_COMPANIES_CURSOR = gql`
  query CompaniesCursor($text: String, $first: Int, $after: String) {
    companiesCursor(text: $text, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
      }
      edges {
        node {
          id
          title
          short_name
          import_num
          logo_path
          link_site
          created_at
          updated_at
        }
        cursor
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($id: ID!) {
    company(id: $id) {
      id
      title
      short_name
      import_num
      logo_path
      link_site
      created_at
      updated_at
    }
  }
`;
