import PostFields from "../fields";
import { Localize } from "components/service";

let postsColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: "id",
        columnIndex: "ID",
        align: "center",
        width: "5%",
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: "title",
        columnIndex: "TITLE",
        width: "15%",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Author</Localize>,
        dataIndex: "author",
        columnIndex: "AUTHOR_ID",
        // width: "10%",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_SellerNumber</Localize>,
        dataIndex: "seller_number",
        columnIndex: "SELLER_NUMBER",
        // width: "5%",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Email</Localize>,
        dataIndex: "email",
        columnIndex: "EMAIL",
        // width: "10%",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_PostDescr</Localize>,
        dataIndex: "description",
        columnIndex: "DESCRIPTION",
        width: "14%",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Likes</Localize>,
        dataIndex: "like_count",
        columnIndex: "LIKE_COUNT",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Comments</Localize>,
        dataIndex: "comment_count",
        columnIndex: "COMMENT_COUNT",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Media</Localize>,
        dataIndex: "media_count",
        columnIndex: "MEDIA_COUNT",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Attachments</Localize>,
        dataIndex: "attached_file_count",
        columnIndex: "ATTACHED_COUNT",
        align: "center",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: "status",
        columnIndex: "STATUS",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_CreateDate</Localize>,
        dataIndex: "created_at",
        columnIndex: "CREATED_AT",
        // width: "10%",
        sorter: true,
    },
    {
        // title: 'action',
        dataIndex: "action",
        align: "center",
        width: "4%",
    },
];

const postsData = ({
    model,
    actionFieldsHide,
    entityRemovePost,
    variables,
}) => {
    if (!model) {
        return [];
    }

    return model.map((post) => {
        return {
            key: post.id,
            id: post.id,
            title: <PostFields.Title post={post} />,
            author: <PostFields.Info post={post} />,
            seller_number: post?.author?.seller_number ?? (
                <strong>&mdash;</strong>
            ),
            email: post?.author?.email ? (
                <a href={`mailto:${post?.author?.email}`}>
                    {post?.author?.email}
                </a>
            ) : (
                <strong>&mdash;</strong>
            ),
            description: <PostFields.Description post={post} />,
            like_count: post.like_count ?? <strong>&mdash;</strong>,
            comment_count: post.comment_count ?? <strong>&mdash;</strong>,
            media_count: post.media_count,
            attached_file_count: post.attached_file_count ?? (
                <strong>&mdash;</strong>
            ),
            status: post.status ?? <strong>&mdash;</strong>,
            created_at: post.getCreatedAt ?? <strong>&mdash;</strong>,
            action: (
                <PostFields.Action
                    post={post}
                    variables={variables}
                    actionFieldsHide={actionFieldsHide}
                    entityRemovePost={entityRemovePost}
                />
            ),
        };
    });
};

const PostsTableHelper = (columnsHide = []) => {
    postsColumns = postsColumns.filter(
        ({ dataIndex }) => !columnsHide?.includes(dataIndex)
    );

    return {
        columns: postsColumns,
        data: postsData,
    };
};

export default PostsTableHelper;
