import React from "react";
import { Form, Input, InputNumber, Button } from "antd";
import { companyUseMutation } from "../../hooks";
import { Localize, EntityRemoveButton } from "components/service";

const CompanyEditForm = ({ company, userID }) => {
  const [form] = Form.useForm();

  const {
    _setCompanyCreateUpdate,
    loadingMutationCompanyCreateUpdate: loading,
  } = companyUseMutation.createUpdate(company?.id);

  const { _setMutationCompanyDelete, loadingMutationCompanyDelete } =
    companyUseMutation.delete(company?.id);

  return (
    <Form
      disabled={userID ?? false}
      key="edit-company-form"
      layout="vertical"
      form={form}
      className="model-form edit-company-form"
      onFinish={(values) => {
        _setCompanyCreateUpdate({
          variables: {
            input: {
              id: company?.id ? +company.id : undefined,
              ...values,
            },
          },
        });
      }}
    >
      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Form.Item
          name="title"
          label={<Localize>FORMS.Input_Label_FullCompanyName</Localize>}
          initialValue={company?.title}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_FullCompanyName</Localize>,
            },
          ]}
        >
          <Input name="title" />
        </Form.Item>

        <Form.Item
          name="short_name"
          label={<Localize>FORMS.Input_Label_ShortName</Localize>}
          initialValue={company?.short_name}
        >
          <Input name="short_name" />
        </Form.Item>
      </div>

      <Form.Item
        name="link_site"
        label={<Localize>FORMS.Input_Label_LinkMainSite</Localize>}
        initialValue={company?.link_site}
        rules={[
          {
            pattern:
              /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
            message: "Please enter a valid URL",
          },
        ]}
      >
        <Input name="link_site" />
      </Form.Item>

      <Form.Item
        name="import_num"
        label={<Localize>FORMS.Input_Label_ImportNumber</Localize>}
        initialValue={company?.import_num}
      >
        <InputNumber
          name="import_num"
          min={1}
          max={1000000}
          controls={false}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <div className="form-btn-holder">
        {company?.id && (
          <EntityRemoveButton
            modalButton={
              <Button>
                <Localize>COMPANY.Button_Text_Delete</Localize>{" "}
              </Button>
            }
            nameEntity={
              Localize({ children: "ENTITY.Modal_Title_Company" }).props
                .children
            }
            dataNameEntity={company?.title}
            loading={loadingMutationCompanyDelete}
            deleteMutation={_setMutationCompanyDelete}
            variables={{
              id: company?.id,
            }}
          />
        )}

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {company?.id ? (
            <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
          ) : (
            <Localize>COMPANY.Button_Text_Create</Localize>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default CompanyEditForm;
