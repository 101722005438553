import CompanyFields from "./fields";
import CompanyForms from "./forms";
import CompanyConst from "./company-const";
import CompanyHelpers from "./helpers";
import { companyUseMutation } from "./hooks";


const Company = {
  Fields: CompanyFields,
  Forms: CompanyForms,
  Const: CompanyConst,
  Helpers: CompanyHelpers,
  Hooks: companyUseMutation
}

export default Company;