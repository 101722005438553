import { useMutation } from "@apollo/client";
import { USER_STATISTICS_EXPORT_EXEL } from "graphql/mutation/user-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";
import { saveAs } from "file-saver";

const useMutationUserStatistic = () => {
  function getFileNameFromUrl(url) {
    const value = url.split("/");
    return value[value.length - 1];
  }

  const [_setMutationUserStatistic, { loading }] = useMutation(
    USER_STATISTICS_EXPORT_EXEL,
    {
      update(cache, { data }) {
        const {
          userStatisticsExportExel: { label, message, file },
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });

        saveAs(file, getFileNameFromUrl(file));
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationUserStatistic,
    loadingMutationUserStatistic: loading,
  };
};

export default useMutationUserStatistic;
