import { Link } from 'react-router-dom';
import CompanyFields from "../fields";
import { Localize } from "components/service";


const companyColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        align: 'center',
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_CompanyLogo</Localize>,
        dataIndex: 'logo_path',
    },
    {
        title: <Localize>TABLES.Column_Title_FullCompanyName</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_ShortName</Localize>,
        dataIndex: 'short_name',
    },
    {
        title: <Localize>TABLES.Column_Title_LinkMainSite</Localize>,
        dataIndex: 'link_site',
    },
    {
        title: <Localize>TABLES.Column_Title_ImportNum</Localize>,
        dataIndex: 'import_num',
        columnIndex: 'IMPORT_NUM',
        sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '4%'
    }
];


const companyData = ({ model, variables }) => {

    if (!model)
    {
        return [];
    }

    return model.map(company => {

        return {
            key: company.id,
            id: company.id ?? <strong>&mdash;</strong>,
            logo_path: <CompanyFields.Logo company={ company } />,
            title: <CompanyFields.Info company={ company } />,
            short_name: company.short_name ?? <strong>&mdash;</strong>,
            link_site: company.link_site ? <Link to={ `//${ company.link_site }` } target="_blank"> { company.link_site }</Link> : <strong>&mdash;</strong>,
            import_num: company.import_num ?? <strong>&mdash;</strong>,
            action: <CompanyFields.Action variables={ variables } companyId={ company.id } companyName={ company.title } />
        };

    });
};


const CompanyTableHelper = {
    columns: companyColumns,
    data: companyData,
}

export default CompanyTableHelper;