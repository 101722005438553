import React from "react";
import { Switch, Route } from 'react-router-dom';
import CompaniesRoute from './company/companies-route';
import UsersRoute from './user/users-route';
import DealersRoute from './dealer/dealers-route';
import PostsRoute from './post/posts-route';
import CommentsRoute from './comment/comments-route';
import HashtagsRoute from './hashtag/hashtags-route';
import СollectionsRoute from './collection/collections-route';
import AnalyticsRoute from './analytic/analytics-route';
import SettingsRoute from './settings/settings-route';

import { Pages } from "components/pages";


const AppRoutes = () => {

    return (

        <Switch>
            <Route path="/" exact>
                <Pages.Dashboard />
            </Route>

            <Route path="/user-questions">
                <Pages.Questions />
            </Route>
            <Route path="/user-questions/page/:pageNum">
                <Pages.Questions />
            </Route>

            <Route path="/companies">
                <CompaniesRoute />
            </Route>

            <Route path="/users">
                <UsersRoute />
            </Route>

            <Route path="/dealers">
                <DealersRoute />
            </Route>

            <Route path="/posts">
                <PostsRoute />
            </Route>

            <Route path="/all-comments">
                <CommentsRoute />
            </Route>

            <Route path="/hashtags">
                <HashtagsRoute />
            </Route>

            <Route path="/collections">
                <СollectionsRoute />
            </Route>

            <Route path="/analytics/sales">
                <AnalyticsRoute />
            </Route>

            <Route path="/settings">
                <SettingsRoute />
            </Route>

            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;